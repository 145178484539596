<template>
  <v-row>
    <v-col>
      <v-card class="mt-2">
        <v-row class="ma-0 pa-0">
          <v-col>
            <v-btn
              class="mt-2"
              color="primary"
              style="margin-bottom: 0;padding-bottom: 0"
              @click="editInvoice(1)"
            >
              + DODAJ
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-autocomplete
              v-model="selectedRisk"
              :items="risks"
              class="mt-2"
              clearable
              dense
              item-text="name"
              item-value="id"
              label="Ryzyka"
              no-data-text="Nie znaleziono ryzka"
              outlined
              multiple
              small-chips
              style="margin-bottom: 0;padding-bottom: 0"
              @change="filterMap()"
            >
              <template #selection="{ item }">
                <v-chip
                  small
                  :color="item.color"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="mt-2"
              clearable
              dense
              outlined
              placeholder="Wyszukaj..."
              style="margin-bottom: 0;padding-bottom: 0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :items="mapList"
        :headers="helpers.mapHeaders"
        fixed-header
        no-results-text="Nie znaleziono błędów"
        class="table-rounded"
        hide-default-footer
        height="80vh"
        item-value="id"
        no-data-text="Nie znaleziono listy błędów"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        :search="search"
        :items-per-page="100"
      >
        <template #[`item.rmNo`]="{item}">
          <div class="d-flex flex-column">
            <v-btn text @click="openRM(item.rmLink)" color="primary">#{{ item.rmNo }}</v-btn>
            <small>{{ item.version }}</small>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            More info about {{ item.name }}
          </td>
        </template>
        <template #[`item.errorLocation`]="{item}">
          <v-chip v-for="i in item.errorLocation" :key="i" small :color="getColor(i)"
                  class="font-weight-medium">
            {{ i }}
          </v-chip>
        </template>
        <template #[`item.risks`]="{item}">
          <v-chip v-for="i in item.risks" :key="i" small :color="getColor(i)"
                  class="font-weight-medium">
            {{ i }}
          </v-chip>
        </template>
        <template #[`item.reasons`]="{item}">
          <v-chip v-for="i in item.reasons" :key="i" small :color="getColor(i)"
                  class="font-weight-medium">
            {{ i }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiClose,
  mdiDotsVertical, mdiExportVariant, mdiEye,
  mdiFileCheckOutline, mdiLaptop,
  mdiMagnify,
  mdiPencilOutline,
  mdiSquareEditOutline
} from '@mdi/js'
import helpers from '@/utils/helpers'
import moment from "moment";

export default {
  name: 'Invoices',
  data() {
    return {
      helpers,
      expanded: [],
      risks: [{ id:1, name: 'niezgodność z zapisami umowy', color: 'error'},
        { id:2, name: 'możliwość niezasadnego wypowiedzenia pożyczki', color: 'red'},
        { id:3, name: 'możliwość sprzedania błędnie rozliczonej sprawy', color: 'red'},
        { id:4, name: 'niezgodność w księgowaniach nie wpływająca na klienta', color: 'red'},
        { id:5, name: 'jakość danych spraw sprzedanych', color: 'red'},
        { id:6, name: 'mniejsza kwota spłacona przez klienta niż wynikająca z umowy', color: 'red'},
        { id:7, name: 'wpływ na raportowanie np. bik i crif', color: 'red'},
        { id:8, name: 'informacja przekazywana do klienta jest błędna', color: 'red'},
        { id:9, name: 'ryzyka z zadań powiązanych', color: 'red'},
        { id:10, name: 'większa kwota spłacona przez klienta niż wynikająca z umowy', color: 'red'},
        { id:12, name: 'mniejsza lub większa kwota spłacona przez klienta niż wynikająca z umowy', color: 'red'},
        { id:13, name: 'brak możliwości wygenerowania wcześniejszej częściowej spłaty', color: 'red'},
        { id:14, name: 'to nie jest błąd', color: 'red'},
        { id:15, name: 'wizerunek', color: 'red'},
        { id:16, name: 'ryzyko przekazania błędnej kwoty nowemu wierzycielowi (sprawy sprzedanej)', color: 'red'}],
      selectedRisk: null,
      selectedReson: null,
      selectedLocation: null,
      singleExpand: true,
      icons: {
        mdiFileCheckOutline,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiLaptop,
        mdiEye,
        mdiClose
      },
      search: '',
      mapList: [{
        "rmNo": "82079",
        "version": 'S7/23',
        "rmLink": "https://sys.bakk.com/issues/82079",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "generowanie harmonogramu"],
        "summary": "Błędna data wymagalności pierwszej raty po wpłacie w dniu wymagalności raty",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "83793",
        "rmLink": "https://sys.bakk.com/issues/83793",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "generowanie harmonogramu"],
        "summary": "Ostatnia rata pożyczki zmienia termin - Poprawka wprowadzona na produkcję, ale sprawy nie zostały poprawione - są prowadzone w nowym zadaniu #85534",
        "risks": ["niezgodność z zapisami umowy", "mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "możliwość sprzedania błędnie rozliczonej sprawy"],
        "reasons": ["błąd w kodzie", "niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "80167",
        "rmLink": "https://sys.bakk.com/issues/80167",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "generowanie harmonogramu", "zmiana terminu płatności", "zmiana stóp procentowych"],
        "summary": "Błąd w kalkulatorze powoduje błędne przenoszenie składników pożyczki między harmonogramami - BAKK nie jest w stanie wskazać błędów, których przyczyną jest błąd w kalkulatorze - lista błędów na sprawach znajduje się w zgłoszeniu ",
        "risks": ["niezgodność z zapisami umowy", "mniejsza lub większa kwota spłacona przez klienta niż wynikająca z umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["błąd kalkulatora", "niedostatecznie szczegółowe testy", "błąd w kodzie"]
      }, {
        "rmNo": "84391 ",
        "rmLink": "https://sys.bakk.com/issues/84391 ",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "odstąpienie"],
        "summary": "Wpłaty wiszą na sprawach z odstąpieniem lub na sprawach, gdzie powinny spowodować wcześniejszą częściową spłatę",
        "risks": ["niezgodność z zapisami umowy", "wpływ na raportowanie np. bik i crif"],
        "reasons": ["nieoptmaly plan zapytania", "błąd rozksięgowania"]
      }, {
        "rmNo": "83845",
        "rmLink": "https://sys.bakk.com/issues/83845",
        "errorLocation": ["proces przyznawania wakacji"],
        "summary": "Przerwa odsetkowa na wakacjach z tarczy",
        "risks": ["niezgodność z zapisami umowy", "większa kwota spłacona przez klienta niż wynikająca z umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "wpływ na raportowanie np. bik i crif", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["błąd implementacji funkcjonalności"]
      }, {
        "rmNo": "84420",
        "rmLink": "https://sys.bakk.com/issues/84420",
        "errorLocation": ["ksieowania online"],
        "summary": "Błędy w naliczeniach odsetek karnych",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy (wykluczenia z procesu)"]
      }, {
        "rmNo": "84373",
        "rmLink": "https://sys.bakk.com/issues/84373",
        "errorLocation": ["proces rozksiegowania"],
        "summary": "Księgowania rozsmarowane po sprawie",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "wpływ na raportowanie np. bik i crif"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "83125",
        "rmLink": "https://sys.bakk.com/issues/83125",
        "errorLocation": ["ksieowania online"],
        "summary": "KS_ONLINE Obsługa przerw odsetkowych",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["błąd rozksięgowania online"]
      }, {
        "rmNo": "79962",
        "rmLink": "https://sys.bakk.com/issues/79962",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "generowanie harmonogramu", "wakacje kredytowe"],
        "summary": "Błędne rozliczenie wcześniejszej częściowej spłaty",
        "risks": ["niezgodność z zapisami umowy", "większa kwota spłacona przez klienta niż wynikająca z umowy", "wpływ na raportowanie np. bik i crif", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "82324",
        "rmLink": "https://sys.bakk.com/issues/82324",
        "errorLocation": ["promocja 60 dni"],
        "summary": "Usuwające się wierzytelności",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy"],
        "reasons": ["niedostatecznie szczegółowe testy", "błąd w kodzie"]
      }, {
        "rmNo": "77882",
        "rmLink": "https://sys.bakk.com/issues/77882",
        "errorLocation": ["dane o opóźnieniach"],
        "summary": "Różnice w wyliczaniu DPD",
        "risks": ["wpływ na raportowanie np. bik i crif", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "82869",
        "rmLink": "https://sys.bakk.com/issues/82869",
        "errorLocation": ["błąd odstąpienia"],
        "summary": "Podwójne umorzenia",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy", "błąd w kodzie"]
      }, {
        "rmNo": "82901",
        "rmLink": "https://sys.bakk.com/issues/82901",
        "errorLocation": ["błąd odstąpienia", "błąd naliczenia odsetek"],
        "summary": "Nienaliczone odsetki na sprawie",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", ""],
        "reasons": ["błąd w kodzie", "błąd naliczenia odsetek"]
      }, {
        "rmNo": "83442",
        "rmLink": "https://sys.bakk.com/issues/83442",
        "errorLocation": ["błąd odstąpienia", "błąd naliczenia odsetek"],
        "summary": "Nienaliczone odsetki - wpłata przed dodaniem odstąpienia",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", ""],
        "reasons": ["błąd w kodzie", "błąd naliczenia odsetek"]
      }, {
        "rmNo": "84114",
        "rmLink": "https://sys.bakk.com/issues/84114",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "uzupełnianie cache"],
        "summary": "Proces wcześniejszej częściowej spłaty deadlock'uje się podczas odświeżania cache_dokument_daty",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "84689",
        "rmLink": "https://sys.bakk.com/issues/84689",
        "errorLocation": ["błąd odstąpienia", "zmiana stóp"],
        "summary": "Błędnie naliczone odsetki umowne po odstąpieniu - zmiana oprocentowania",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["błąd naliczenia odsetek", "niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "77613",
        "rmLink": "https://sys.bakk.com/issues/77613",
        "errorLocation": ["spłata całkowita"],
        "summary": "Błąd księgowanie - umorzenie na spłatę całkowitą",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["błąd rozksięgowań"]
      }, {
        "rmNo": "85147",
        "rmLink": "https://sys.bakk.com/issues/85147",
        "errorLocation": ["wakacje kredyowe płatne"],
        "summary": "Wakacje kredytowe płatne - absolutna porażka",
        "risks": ["informacja przekazywana do klienta jest błędna", "niezgodność z zapisami umowy", "wpływ na raportowanie np. bik i crif", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "84721",
        "rmLink": "https://sys.bakk.com/issues/84721",
        "errorLocation": ["spłata całkowita", "rozliczenie prowizji"],
        "summary": "Błędnie rozliczona prowizja po spłacie całkowitej - za wysoki zwrot nadpłaty",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "85265",
        "rmLink": "https://sys.bakk.com/issues/85265",
        "errorLocation": ["wcześniejszejsza częściowa spłata"],
        "summary": "Wpłaty rozksięgowane tylko na nadpłaty - wcześniejsza spłata",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "83381",
        "rmLink": "https://sys.bakk.com/issues/83381",
        "errorLocation": ["rozwój"],
        "summary": "Tabela audytowa - ewidencja zmian wprowadzanych na pożyczkach",
        "risks": ["to nie jest błąd"],
        "reasons": ["-"]
      }, {
        "rmNo": "81197",
        "rmLink": "https://sys.bakk.com/issues/81197",
        "errorLocation": ["spłata całkowita", "rozliczenie prowizji"],
        "summary": "Rozliczenie prowizji",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "85175",
        "rmLink": "https://sys.bakk.com/issues/85175",
        "errorLocation": ["generowanie harmonogramu"],
        "summary": "Sprawy z harmonogramami 'restrukturyzacja'",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy", "możliwość sprzedania błędnie rozliczonej sprawy"],
        "reasons": ["decyzja biznesowa"]
      }, {
        "rmNo": "85531",
        "rmLink": "https://sys.bakk.com/issues/85531",
        "errorLocation": ["wcześniejszejsza częściowa spłata", "rozksięgowanie online"],
        "summary": "Na spłaconych ratach naliczane są odsetki karne",
        "risks": ["niezgodność z zapisami umowy", "możliwość niezasadnego wypowiedzenia pożyczki", "wpływ na raportowanie np. bik i crif"],
        "reasons": ["błąd ludzki"]
      }, {
        "rmNo": "85621",
        "rmLink": "https://sys.bakk.com/issues/85621",
        "errorLocation": ["spłata całkowita", "rozliczenie prowizji"],
        "summary": "Błędnie rozliczona prowizja po spłacie całkowitej - za wysoki zwrot nadpłaty",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["-"]
      }, {
        "rmNo": "85632",
        "rmLink": "https://sys.bakk.com/issues/85632",
        "errorLocation": ["rozksięgowanie online"],
        "summary": "Błędna księgowania na konto nadpłaty, nadpłaty podwajają się",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "85682",
        "rmLink": "https://sys.bakk.com/issues/85682",
        "errorLocation": ["rozksięgowanie"],
        "summary": "Podwóje rozksiegowanie wpłaty",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta", "mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy"],
        "reasons": ["błąd ludzki"]
      }, {
        "rmNo": "83479",
        "rmLink": "https://sys.bakk.com/issues/83479",
        "errorLocation": ["rozksięgowanie online"],
        "summary": "Księgowania online - obsługa wykluczeń związanych z wpłatami indentyfikacyjnymi",
        "risks": ["to nie jest błąd"],
        "reasons": ["-"]
      }, {
        "rmNo": "85423",
        "rmLink": "https://sys.bakk.com/issues/85423",
        "errorLocation": ["rozksięgowanie online"],
        "summary": "Zawieszanie się kolejki księgowań online",
        "risks": ["informacja przekazywana do klienta jest błędna", "niezgodność z zapisami umowy"],
        "reasons": ["nieznany"]
      }, {
        "rmNo": "82953",
        "rmLink": "https://sys.bakk.com/issues/82953",
        "errorLocation": ["rozwój"],
        "summary": "Narzędzie do wygenerowania wcześniejszej częściowej spłaty - odkładanie danych",
        "risks": ["brak możliwości wygenerowania wcześniejszej częściowej spłaty", "wpływ na raportowanie np. bik i crif"],
        "reasons": ["-"]
      }, {
        "rmNo": "84086",
        "rmLink": "https://sys.bakk.com/issues/84086",
        "errorLocation": ["księga pomocnicza"],
        "summary": "Błędne umorzenia kapitału w raporcie księgowym",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["brak danych"]
      }, {
        "rmNo": "83812",
        "rmLink": "https://sys.bakk.com/issues/83812",
        "errorLocation": ["rozwój"],
        "summary": "Nie wszystkie harmonogramy generuja się przez strukturę buforową (np payu)",
        "risks": ["wpływ na raportowanie np. bik i crif"],
        "reasons": ["-"]
      }, {
        "rmNo": "83861",
        "rmLink": "https://sys.bakk.com/issues/83861",
        "errorLocation": ["rozwój"],
        "summary": "[Finanse] Warstwa pośrednia na potrzeby raportowe",
        "risks": ["to nie jest błąd"],
        "reasons": ["-"]
      }, {
        "rmNo": "86076",
        "rmLink": "https://sys.bakk.com/issues/86076",
        "errorLocation": ["księgowania"],
        "summary": "Zmiana alokacji składowych rozksięgowanej wpłaty w historycznych wpłatach spraw sprzedanych",
        "risks": ["jakość danych spraw sprzedanych", "niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["błąd ludzki"]
      }, {
        "rmNo": "86002",
        "rmLink": "https://sys.bakk.com/issues/86002",
        "errorLocation": ["księga pomocnicza"],
        "summary": "Deadlock na KS_POM",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["gsą  rozksięgowania pomocnicza"]
      }, {
        "rmNo": "71582",
        "rmLink": "https://sys.bakk.com/issues/71582",
        "errorLocation": ["zwrot nadpłat"],
        "summary": "Błąd nadpłaty na sprawie",
        "risks": ["ryzyko przekazania błędnej kwoty nowemu wierzycielowi (sprawy sprzedanej)"],
        "reasons": ["błąd w kodzie", "błąd rozksięgowań"]
      }, {
        "rmNo": "75980",
        "rmLink": "https://sys.bakk.com/issues/75980",
        "errorLocation": ["księgowania"],
        "summary": "Błąd nadpłaty na sprawie",
        "risks": ["niezgodność z zapisami umowy"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "78127",
        "rmLink": "https://sys.bakk.com/issues/78127",
        "errorLocation": ["księga pomocnicza"],
        "summary": "KSPOM - Ujemne przychody",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["dostatecz szczegółowe testy", "błąd p kodzie"]
      }, {
        "rmNo": "81099",
        "rmLink": "https://sys.bakk.com/issues/81099",
        "errorLocation": ["księga pomocnicza"],
        "summary": "W systemie istnieją ujemne wartości",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["błąd danych w ks_pom"]
      }, {
        "rmNo": "81197 ",
        "rmLink": "https://sys.bakk.com/issues/81197 ",
        "errorLocation": ["księgowania"],
        "summary": "Rozliczenie prowizji z 2020",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["rozliczenie prowizji z 2020", "błąd decyzji biznesowej smartney"]
      }, {
        "rmNo": "82507",
        "rmLink": "https://sys.bakk.com/issues/82507",
        "errorLocation": ["wakacje kredyowe"],
        "summary": "Oszacowanie liczby błędów wynikających z braku przerw odsetkowych na sprawach i ich poprawienie - Cz II",
        "risks": ["niezgodność z zapisami umowy", "możliwość niezasadnego wypowiedzenia pożyczki", "wpływ na raportowanie np. bik i crif", "większa kwota spłacona przez klienta niż wynikająca z umowy"],
        "reasons": ["błąd decyzji biznesowej"]
      }, {
        "rmNo": "83339",
        "rmLink": "https://sys.bakk.com/issues/83339",
        "errorLocation": ["rozwój"],
        "summary": "Błędy automatycznych zwrotów (koncepcja zmiany)",
        "risks": ["to nie jest błąd"],
        "reasons": ["-"]
      }, {
        "rmNo": "84128",
        "rmLink": "https://sys.bakk.com/issues/84128",
        "errorLocation": ["sprawy sprzedane"],
        "summary": "Przeliczenie odsetek karnych na sprzedanej pożyczce po wpłacie",
        "risks": ["jakość danych spraw sprzedanych", "ryzyko przekazania błędnej kwoty nowemu wierzycielowi (sprawy sprzedanej)"],
        "reasons": ["błąd rozksięgowania online ", "błąd wprowadzania umorzeń w trakcie procesu sprzedaży "]
      }, {
        "rmNo": "84252",
        "rmLink": "https://sys.bakk.com/issues/84252",
        "errorLocation": ["rozwój"],
        "summary": "Przypadki testowe do obsługi przerw odsetkowych",
        "risks": ["to nie jest błąd"],
        "reasons": ["odblokowanie wykluczonych spraw z ksiegowania online"]
      }, {
        "rmNo": "85144",
        "rmLink": "https://sys.bakk.com/issues/85144",
        "errorLocation": ["kalkulator wyliczający wcześniejszą częściową spłatę"],
        "summary": "Błąd wyliczania kwoty do wcześniejszej spłaty",
        "risks": ["informacja przekazywana do klienta jest błędna"],
        "reasons": ["błąd wakacji kredytowych \\n", "błąd kalkulatora wyliczającego kwotę do wcześniejszej spłaty\\n", "błąd decyzji biznesowej"]
      }, {
        "rmNo": "85179",
        "rmLink": "https://sys.bakk.com/issues/85179",
        "errorLocation": ["rozwój"],
        "summary": "Ujednolicenie kalkulatorów harmonogramów ",
        "risks": ["to nie jest błąd"],
        "reasons": ["ma rozwiązać:błąd kalkulatorów wynikające z różnic między nimi oraz rozwiązanie problemu wersjonowania"]
      }, {
        "rmNo": "85261",
        "rmLink": "https://sys.bakk.com/issues/85261",
        "errorLocation": ["generowanie harmonogramu"],
        "summary": "Poprawienie formuł w kalkulatorach",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "informacja przekazywana do klienta jest błędna", "niezgodność z zapisami umowy"],
        "reasons": ["bład kalkulatora", "niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "85534",
        "rmLink": "https://sys.bakk.com/issues/85534",
        "errorLocation": ["wakacje kredyowe", "wcześniejsza częściowa spłata", "zmiana terminu płatności", "generowanie harmongramu", "wypowiedzenia"],
        "summary": "Poprawienie spraw objętych błędami",
        "risks": ["ryzyka z zadań powiązanych"],
        "reasons": ["błąd kalkulatora - wyliczanie ujemnych składników rat ", "błąd parametrów przekazywanych do kalkulatora ", "błąd decyzji biznesowej smartney ", "bład przerw odsetkowych", "błąd decyzji biznesowej"]
      }, {
        "rmNo": "86197",
        "rmLink": "https://sys.bakk.com/issues/86197",
        "errorLocation": ["ksieowania online", "wcześniejsza częściowa spłata"],
        "summary": "Blokada procesu wcześniejszej częściowej spłaty z księgowaniami online",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "wizerunek", "niezgodność z zapisami umowy"],
        "reasons": ["zakleszczenie się procesu z księgowaniem online"]
      }, {
        "rmNo": "86204",
        "rmLink": "https://sys.bakk.com/issues/86204",
        "errorLocation": ["księgowania"],
        "summary": "Sprawa zamknięta niespłacona w całości",
        "risks": ["mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["otwarte ksiegowania wpłat"]
      }, {
        "rmNo": "83951",
        "rmLink": "https://service.smartney.pl/issues/83951",
        "errorLocation": ["rozwój"],
        "summary": "Zmiany w strukurze ksiegowej",
        "risks": ["to nie jest błąd"],
        "reasons": ["-"]
      }, {
        "rmNo": "86444",
        "rmLink": "https://sys.bakk.com/issues/86444",
        "errorLocation": ["wakacje kredytowe"],
        "summary": "Rozksięgowanie opłaty wakacji kredytowych",
        "risks": ["wizerunek", "niezgodność z zapisami umowy", "mniejsza kwota spłacona przez klienta niż wynikająca z umowy", "informacja przekazywana do klienta jest błędna"],
        "reasons": ["niedostatecznie szczegółowe testy", "błąd decyzji biznesowych", "błąd wakacji kredytowych "]
      }, {
        "rmNo": "86347",
        "rmLink": "https://sys.bakk.com/issues/86347",
        "errorLocation": ["zwrot nadpłat"],
        "summary": "Błędne kwoty nadpłat",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy", "błąd w kodzie"]
      }, {
        "rmNo": "85464",
        "rmLink": "https://sys.bakk.com/issues/85464",
        "errorLocation": ["generowanie harmonogramu"],
        "summary": "Błędny parametr odsetkowy - poprawa spraw",
        "risks": ["niezgodność z zapisami umowy", "możliwość niezasadnego wypowiedzenia pożyczki", "wpływ na raportowanie np. bik i crif", "większa kwota spłacona przez klienta niż wynikająca z umowy", "możliwość sprzedania błędnie rozliczonej sprawy", "możliwość niezasadnego wypowiedzenia pożyczki"],
        "reasons": ["błąd decyzji biznesowej"]
      }, {
        "rmNo": "86370",
        "rmLink": "https://sys.bakk.com/issues/86370",
        "errorLocation": ["zwrot nadpłat"],
        "summary": "Brakujące ksiegowania nadpłat",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["błąd procedury zwracającej nadpłaty"]
      }, {
        "rmNo": "86393",
        "rmLink": "https://sys.bakk.com/issues/86393",
        "errorLocation": ["wakacje kredytowe", "generowanie harmonogramu"],
        "summary": "Brak powodu powstania wierzytelności na sprawach gdzie wprowadzone zostały wakacje z tarczy",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "86394",
        "rmLink": "https://sys.bakk.com/issues/86394",
        "errorLocation": ["proces upadłości konsumenckiej", "generowanie harmonogramu"],
        "summary": "Brak powodu powstania wierzytelności na sprawach gdzie wprowadzone została dodana upadłość konsumencka",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "86395",
        "rmLink": "https://sys.bakk.com/issues/86395",
        "errorLocation": ["wcześniejsza częściowa spłata", "generowanie harmonogramu"],
        "summary": "Brak powodu powstania wierzytelności na sprawach gdzie miała miejsce wcześniejsza częściowa spłata",
        "risks": ["niezgodność w księgowaniach nie wpływająca na klienta"],
        "reasons": ["niedostatecznie szczegółowe testy"]
      }, {
        "rmNo": "86257",
        "rmLink": "https://sys.bakk.com/issues/86257",
        "errorLocation": ["wakacje kredytowe", "wcześniejsza częściowa spłata"],
        "summary": "Dostosowanie wcześniejszej częściowej spłaty do wakacji kredytowych",
        "risks": ["większa kwota spłacona przez klienta niż wynikająca z umowy", "niezgodność z zapisami umowy", "możliwość niezasadnego wypowiedzenia pożyczki", "wpływ na raportowanie np. bik i crif"],
        "reasons": ["błąd decyzji biznesowej", "niedostatecznie szczegółowe testy"]
      }]
    }
  },
  mounted() {
    // this.refreshDocuments()
  },
  methods: {
    // refreshDocuments() {
    //   this.invoiceList.forEach((item) => {
    //     item.receiptDate = moment(item.receiptDate).format('DD-MM-YYYY')
    //     item.sellDate = moment(item.receiptDate).format('DD-MM-YYYY')
    //     item.paymentDate = moment(item.receiptDate).format('DD-MM-YYYY')
    //     item.creationDate = moment(item.receiptDate).format('DD-MM-YYYY')
    //   })
    // },
    openRM(rmLink) {
      window.location.assign(rmLink)
    },
    getColor(reason) {
      if (reason == 'generowanie harmonogramu') {
        return 'primary'
      } else if (reason == 'wcześniejszejsza częściowa spłata') {
        return 'success'
      }
    },
    editInvoice(id) {
      if (id) {
        this.$router.push({name: 'add_invoice'})
      } else {
        this.$router.push({name: 'add_invoice'})
      }
    }
  }
}
</script>

<style>

</style>
