var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mt-2"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',[_c('v-btn',{staticClass:"mt-2",staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.editInvoice(1)}}},[_vm._v(" + DODAJ ")])],1),_c('v-col',{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"cols":"3","md":"3"}},[_c('v-autocomplete',{staticClass:"mt-2",staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"items":_vm.risks,"clearable":"","dense":"","item-text":"name","item-value":"id","label":"Ryzyka","no-data-text":"Nie znaleziono ryzka","outlined":"","multiple":"","small-chips":""},on:{"change":function($event){return _vm.filterMap()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.selectedRisk),callback:function ($$v) {_vm.selectedRisk=$$v},expression:"selectedRisk"}})],1),_c('v-col',{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{staticClass:"mt-2",staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"clearable":"","dense":"","outlined":"","placeholder":"Wyszukaj..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"items":_vm.mapList,"headers":_vm.helpers.mapHeaders,"fixed-header":"","no-results-text":"Nie znaleziono błędów","hide-default-footer":"","height":"80vh","item-value":"id","no-data-text":"Nie znaleziono listy błędów","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":"","search":_vm.search,"items-per-page":100},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.rmNo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openRM(item.rmLink)}}},[_vm._v("#"+_vm._s(item.rmNo))]),_c('small',[_vm._v(_vm._s(item.version))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.name)+" ")])]}},{key:"item.errorLocation",fn:function(ref){
var item = ref.item;
return _vm._l((item.errorLocation),function(i){return _c('v-chip',{key:i,staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.getColor(i)}},[_vm._v(" "+_vm._s(i)+" ")])})}},{key:"item.risks",fn:function(ref){
var item = ref.item;
return _vm._l((item.risks),function(i){return _c('v-chip',{key:i,staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.getColor(i)}},[_vm._v(" "+_vm._s(i)+" ")])})}},{key:"item.reasons",fn:function(ref){
var item = ref.item;
return _vm._l((item.reasons),function(i){return _c('v-chip',{key:i,staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.getColor(i)}},[_vm._v(" "+_vm._s(i)+" ")])})}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }